import { BaseDataImport } from './data-import-base';
import { ColumnMapping, ExecuteDataImport, SelectFile } from './steps';

export class WizardManager extends BaseDataImport {
    FIELDS_ROUTE = 'get_import_fields';
    PARSE_FILE_ROUTE = 'parse_file';
    IMPORT_ROUTE = 'initiate_import';
    IMPORT_PERCENTAGE_ROUTE = 'import_percentage';
    IMPORT_DATA_CONFIRMATION_ROUTE = 'import_data_confirmation';
    MAP_HISTORY_ROUTE = 'map_histories';

    STEPS = [SelectFile, ColumnMapping, ExecuteDataImport];

    constructor() {
        super();
        this.mapperModel = [];
        this.mapperFileReference = [];
        this.fileSamples = [];
        this.filePreviewHeaders = [];
        this.stepsInstances = new Map();
        this._steps_by_name = [];
        this.currentStep = {};
        this.canNext = false;
        this.canPrevious = false;
        this.loaded = true;
    }

    async init() {
        for (const step of this.STEPS) {
            this._steps_by_name.push(step.dataName);
        }
        this.currentStep = this._steps_by_name[0];
        await this.loadImportFields();

        window.Alpine.effect(() => {
            this.validate();
        });
    }

    validate() {
        this.canNext = this.checkCanNext();
        this.canPrevious = this.checkCanPrevious();
    }

    get mandatoryFields() {
        return this.mapperModel.filter((x) => x.required);
    }
    get optionalFields() {
        return this.mapperModel.filter((x) => x.required === false);
    }
    get canMapperColumns() {
        return this.mapperFileReference?.length > 0;
    }

    getMapperFileReference(col) {
        const filter = this.mapperFileReference.filter((x) => x.column.toString() === col);
        return filter.length === 0 ? null : filter[0];
    }

    async next() {
        let idx = this._steps_by_name.indexOf(this.currentStep);
        const previousStepInstance = this.stepsInstances.get(this.currentStep);
        await previousStepInstance.postStageAction();

        this.currentStep =
            idx + 1 > this._steps_by_name.length ? this._steps_by_name[idx] : this._steps_by_name[idx + 1];
        const currentStepInstance = this.stepsInstances.get(this.currentStep);
        await currentStepInstance.preStageAction();

        if (currentStepInstance instanceof ExecuteDataImport) {
            await currentStepInstance.initialFileImport();
        }
    }
    async previous() {
        let idx = this._steps_by_name.indexOf(this.currentStep);
        this.currentStep = idx === 0 ? this._steps_by_name[0] : this._steps_by_name[idx - 1];
        const currentStepInstance = this.stepsInstances.get(this.currentStep);
        await currentStepInstance.preStageAction();
    }

    checkCanNext() {
        if (this.stepsInstances.size === 0) {
            return false;
        }

        const currentStepInstance = this.stepsInstances.get(this.currentStep);
        if (!currentStepInstance || !currentStepInstance.validate()) {
            return false;
        }
        let idx = this._steps_by_name.indexOf(this.currentStep.name);
        return idx + 1 <= this.stepsInstances.size;
    }

    checkCanPrevious() {
        let idx = this._steps_by_name.indexOf(this.currentStep.name);
        return idx - 1 >= 0;
    }

    async loadImportFields() {
        const results = await this.request(this.FIELDS_ROUTE);
        if (results.isSuccessful && results.data.length > 0) {
            for (const x of results.data) {
                this.mapperModel.push({ column: null, field: x, required: x.required });
            }
        }
    }

    async parseFile() {
        const csrfToken = jQuery('[name=csrfmiddlewaretoken]').val();
        const postData = new FormData(this.alpine.$refs.form);

        const res = await this.request(this.PARSE_FILE_ROUTE, {
            method: 'POST',
            body: postData,
            headers: {
                'X-CSRFToken': csrfToken,
            },
        });
        if (res.isSuccessful) {
            this.mapperFileReference = res.data.mapper_file_reference || [];
            this.fileSamples = res.data.samples || [];
            this.filePreviewHeaders = res.data.headers || [];
        }
    }
}
