import { WizardBase } from '../wizard-base';

export class ConfirmDataImport extends WizardBase {
    constructor(manager) {
        super(manager);
        this.dataConfirmPreview = [];
        this.dataConfirmPreviewHeader = [];
    }
    static dataName = 'confirmDataImport';

    async preStageAction() {
        const postData = new FormData(this.alpine.$refs.form);
        for (const y of this._manager.mapperModel) {
            postData.append('field', y.field.name);
            postData.append('column', y.column);
        }
        const csrfToken = jQuery('[name=csrfmiddlewaretoken]').val();
        const res = await this.request(this._manager.IMPORT_DATA_CONFIRMATION_ROUTE, {
            method: 'POST',
            body: postData,
            headers: {
                'X-CSRFToken': csrfToken,
            },
        });
        if (res.isSuccessful) {
            this.dataConfirmPreview = res.data;
            if (this.dataConfirmPreview.length > 0) {
                this.dataConfirmPreviewHeader = Object.keys(this.dataConfirmPreview[0]);
            }
        }
    }

    validate() {
        return this.dataConfirmPreviewHeader.length > 0;
    }

    getValues(data) {
        return Object.values(data);
    }
}
