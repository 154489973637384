// Load plugins
import cash from 'cash-dom';
import helper from './helper';
import Velocity from 'velocity-animate';
// import TomSelect from 'tom-select/dist/js/tom-select.complete.js';
import * as Popper from '@popperjs/core';
import daterangepicker from 'daterangepicker/daterangepicker.js';

import $ from 'jquery';
import ApexCharts from 'apexcharts';
import moment from 'moment';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js';
import HoverPlugin from 'wavesurfer.js/dist/plugins/hover.esm.js';
import Alpine from 'alpinejs';
import mask from '@alpinejs/mask';
import collapse from '@alpinejs/collapse';
// import count from './alpinejs-extra/character-count'
import Croppr from 'croppr';
// import { count } from "sms-length";
import { default as sms_counter } from './sms/sms-length';
import htmx from 'htmx.org';

// Set plugins globally
window.cash = cash;
window.helper = helper;
window.Velocity = Velocity;
window.Popper = Popper;
window.daterangepicker = daterangepicker;
window.jQuery = $;
window.$ = $;
window.ApexCharts = ApexCharts;
window.WaveSurfer = WaveSurfer;
window.TimelinePlugin = TimelinePlugin;
window.HoverPlugin = HoverPlugin;
window.SMSCount = sms_counter;
// window.TomSelect = TomSelect;
window.moment = moment;
window.Alpine = Alpine;
window.Alpine.plugin(mask);
window.Alpine.plugin(collapse);
// window.Alpine.plugin(count);
window.Croppr = Croppr;
window.htmx = htmx;
