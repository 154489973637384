import { BaseDataImport } from './data-import-base';

export class WizardBase extends BaseDataImport {
    constructor(manager) {
        super();
        this._manager = manager;
    }

    static dataName = 'wizardBase';

    async init() {}

    validate() {
        return true;
    }

    async preStageAction() {
        // will be executed when entering the step
    }

    async postStageAction() {
        // will be executed when leaving the step
    }
}
