import { WizardManager } from './wizard-manager';

export class DataImportProcessBase {
    constructor() {
        this.manager = new WizardManager();
    }
    async init() {
        this.manager = window.Alpine.reactive(this.manager);
        await this.manager.init();
        this.manager.alpine = this;
    }

    initializeStepAsData() {
        const manager = this.manager;
        for (const step of manager.STEPS) {
            window.Alpine.data(step.dataName, () => {
                return {
                    step: {},
                    async init() {
                        this.step = window.Alpine.reactive(new step(manager));
                        this.step.alpine = this;
                        await this.step.init();
                        manager.stepsInstances.set(step.dataName, this.step);
                        manager.validate();
                    },
                };
            });
        }
    }
}
