import { MediaTab } from '~/js/mms-control/base';

export class AddNewTab extends MediaTab {
    constructor() {
        super();
        this.selectedFile = null;
        this.name = 'addTab';
        this.croppr = null;
        this.nodes = [];
        this.file = {
            name: '',
            type: '',
        };
        this.canUpload = false;
        this.alpine_instance = null;
    }

    async init(alpine_instance) {
        alpine_instance.$watch('addTab.selectedFile', (newValue) =>
            this.onSelectedFileChanged(newValue, alpine_instance)
        );
        this.alpine_instance = alpine_instance;
    }

    onSelectedFileChanged(newValue, alpine_instance) {
        if (this.nodes.length > 0) {
            this.nodes = [];
        }
        this.setImage(alpine_instance.$refs.fileInput.files, alpine_instance).then(() => {
            alpine_instance.$nextTick(() => {
                this.initCropper();
                this.canUpload = true;
            });
        });
    }

    openFileDialog(fileInput) {
        this.alpine_instance.$refs.fileInput.click();
    }

    async uploadAction() {
        if (this.file && this.croppr) {
            const csrfToken = jQuery('[name=csrfmiddlewaretoken]').val();
            const createMediaURL = this.constructMediaURL(MediaTab.ADD_MEDIA_URL);
            const formData = new FormData();

            for (const [key, value] of Object.entries(this.croppr.getValue())) {
                formData.append(key, value);
            }
            formData.append('media', this.file, this.file.name);

            const res = await this.request(createMediaURL, {
                method: 'POST',
                body: formData,
                headers: {
                    'X-CSRFToken': csrfToken,
                },
            });
            if (res.isSuccessful) {
                this.addMedia(res.data);
            }
        }
    }

    initCropper() {
        this.croppr = new Croppr('#croppr', {
            startSize: [80, 80, '%'],
        });
    }

    setImage(files) {
        return new Promise((resolve, reject) => {
            if (files.length === 0) {
                return reject();
            }
            const file = files[0];
            if (file.type.indexOf('image/') === -1) {
                alert('Please select an image file');

                return reject();
            }
            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.nodes.push(event.target?.result);
                    this.file = file;
                    resolve();
                };
                reader.readAsDataURL(file);
            } else {
                alert('Sorry, FileReader API not supported');
            }
        });
    }

    reset() {
        this.selectedFile = null;
        this.croppr = null;
        this.nodes = [];
        this.file = {
            name: '',
            type: '',
        };
        this.canUpload = false;
    }
}
